<template>
  <div>
    <!-- Manage Permissions Button -->
    <b-button
      id="tour-permissions-btn"
      v-b-tooltip.hover="$t('permissions.manage-permissions')"
      v-b-modal.manage-permissions-modal
      variant="primary"
      :class="classes"
    >
      <i class="fa fa-user-lock" />
    </b-button>

    <!-- Manage Permissions Modal -->
    <b-modal
      id="manage-permissions-modal"
      :title="$t('permissions.manage-permissions')"
      ok-title="Close"
      ok-only
      no-close-on-backdrop
      @shown="modalShown"
      @hidden="() => { form.slug = ''; searchTerm = '' }"
    >
      <div id="tour-permission-form">
        <b-form-group :label="$t('permissions.permission')">
          <v-select
            v-model="form.slug"
            :reduce="p => p.value"
            :options="permissionOptions"
          />
        </b-form-group>

        <b-form-group :label="$t('permissions.search-user')">
          <b-form-input
            v-model="searchTerm"
            placeholder="Search User"
            @input="getUsers"
          />
        </b-form-group>
        <b-overlay :show="isLoading">
          <b-table
            id="project-users-table"
            :items="users"
            :fields="fields"
            responsive
            show-empty
          >
            <template #head()="data">
              <span class="text-capitalize">
                {{ data.label }}
              </span>
            </template>

            <template #cell(name)="data">
              <b-avatar
                :src="getAvatar(data.item.avatar)"
                size="30"
                badge
                :badge-variant="data.item.isOnline ? 'success' : 'secondary'"
                :text="data.item.name.charAt(0)"
                class="mr-1"
                variant="light-primary"
              /> {{ data.item.name }}
            </template>

            <template #cell(actions)="data">
              <b-form-checkbox
                v-model="data.item.hasPermission"
                class="colorful-switch"
                switch
                @change="updatePermission(data.item)"
              >
                <span class="switch-icon-left">
                  <feather-icon
                    icon="CheckIcon"
                    color="#ffffff"
                  />
                </span>
                <span
                  class="switch-icon-right"
                >
                  <feather-icon
                    icon="XIcon"
                    color="#ffffff"
                  />
                </span>
              </b-form-checkbox>
            </template>
          </b-table>

          <div class="float-right">
            <!-- Pagination -->
            <b-pagination
              v-if="total > perPage"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              aria-controls="project-users-table"
              @input="() => { isLoading = true; getUsers() }"
            />
          </div>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, VBTooltip, BModal, BOverlay, BTable, BPagination, BFormGroup, BAvatar, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useApollo from '@/plugins/graphql/useApollo'
import EventBus from '@/event-bus'
import _ from 'lodash'
import { continueTour, isActiveTourStep, setComponentReady } from '@/views/tour/tour'
import { ownerTourIndexes } from '@/views/tour/tourConstants'

export default {
  components: {
    BButton,
    BModal,
    BOverlay,
    BTable,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BAvatar,
    BFormInput,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classes: {
      type: String,
      default: () => '',
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      continueTour,
      users: [],
      total: 0,
      isLoading: true,
      fields: [
        { key: 'name', label: 'name' },
        {
          key: 'actions', label: 'actions', thStyle: 'width: 10%;', tdClass: 'text-center',
        },
      ],
      form: {
        slug: '',
        allow: true,
      },
      perPage: 6,
      currentPage: 1,
      projectUid: this.$store.state.project.selectedProject,
      searchTerm: '',
    }
  },
  computed: {
    allPermissions() {
      return this.$store.state.project.allPermissions
    },
    permissionOptions() {
      return this.allPermissions ? this.permissions.map(option => ({
        label: this.allPermissions.find(permission => permission.slug === option).description,
        value: option,
      })) : []
    },
  },
  watch: {
    'form.slug': {
      handler(val) {
        if (val) {
          this.currentPage = 1
          this.getUsers()
        }
      },
    },
  },
  mounted() {
    EventBus.$on('permission-updated', permission => {
      if (this.permissions[0] === permission) {
        this.form.slug = permission
        setTimeout(() => this.getUsers(), 2000)
      }
    })
  },
  methods: {
    modalShown() {
      this.isLoading = true
      // eslint-disable-next-line prefer-destructuring
      this.form.slug = this.permissions[0]
    },
    getUsers: _.debounce(function () {
      this.isLoading = true
      useApollo.project.userProjectWithPermission({
        slug: this.form.slug,
        keyword: this.searchTerm,
        projectId: this.projectUid,
        first: this.perPage,
        page: this.currentPage,
      }).then(response => {
        const data = response.data.project.users
        this.users = data.data
        this.total = data.total
        if (this.form.allow) this.form.users = this.users.filter(user => user.hasPermission)
        else this.form.users = this.users.filter(user => !user.hasPermission)
      }).finally(() => {
        this.isLoading = false
        if (isActiveTourStep(ownerTourIndexes.TOUR_PERMISSIONS_BTN) || isActiveTourStep(ownerTourIndexes.TOUR_MONITOR_USERS)) {
          setComponentReady()
        }
      })
    }, 1000),
    updatePermission(user) {
      this.isLoading = true
      const params = {
        slug: this.form.slug,
        companyUid: this.$store.state.project.selectedCompany,
        projectUid: this.projectUid,
        input: {
          userUid: user.uuid,
          action: user.hasPermission ? 'ALLOW' : 'BLOCK',
        },
      }

      useApollo.project.updateProjectPermission(params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.updateProjectPermission.message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isLoading = false })
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/base/pages/ui-colors.scss";
</style>
