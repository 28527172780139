<template>
  <b-pagination
    v-if="totalItems >= perPage"
    :value="1"
    :total-rows="totalItems"
    :per-page="perPage"
    first-number
    last-number
    align="right"
    prev-class="prev-item"
    next-class="next-item"
    class="mt-1 mb-0"
    @input="(value) => $emit('onPaginationChange', value)"
  >
    <template #prev-text>
      <feather-icon
        icon="ChevronLeftIcon"
        size="18"
      />
    </template>
    <template #next-text>
      <feather-icon
        icon="ChevronRightIcon"
        size="18"
      />
    </template>
  </b-pagination>
</template>
<script>
import {
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BPagination,
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 20,
    },
  },
}
</script>
