<template>
  <div>
    <b-overlay :show="checkingPermission">
      <div
        v-if="checkingPermission || canUpdateProductivity"
        id="tour-productivity"
      >
        <div class="custom-search d-flex justify-content-between">
          <div>
            <b-button
              v-b-modal.add-edit-productivity
              variant="outline-primary"
            >
              {{ $t('Add More') }}
            </b-button>
          </div>
          <div class="d-flex">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1"> {{ $t('Search') }}</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <manage-permissions
              v-if="canManagePermissions"
              :permissions="permissions"
              class="ml-1"
            />
          </div>
        </div>
        <b-overlay :show="!checkingPermission && isProcessing">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <span v-if="props.column.field === 'productivity'">
                <!-- <b-form-radio-group
                v-model="productivity[props.index]"
                :options="productivityList"
                @change="(value)=>updateProductivitySetting(props,value)"
              /> -->
                <tristate
                  v-model="productivity[props.index]"
                  tooltip-yes="Productive"
                  tooltip-no="Unproductive"
                  tooltip-neutral="Neutral"
                  @change="(value)=>updateProductivitySetting(props,value)"
                />
              </span>
            </template>
          </vue-good-table>
        </b-overlay>
        <pagination
          v-if="rows.length"
          :total-items="totalItems"
          @onPaginationChange="getProjectProductivitySettings"
        />
        <add-edit
          @onSave="getProjectProductivitySettings"
        />
      </div>
      <no-permission v-else />
    </b-overlay>
  </div>
</template>
<script>
import {
  BFormGroup, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useApollo from '@/plugins/graphql/useApollo'
import Pagination from '@/views/common/components/Pagination.vue'
import ManagePermissions from '@/views/common/components/ManagePermissions.vue'
import NoPermission from '@/views/common/components/NoPermission.vue'
import { checkPermission } from '@/utils/permissions'
import EventBus from '@/event-bus'
import { setComponentReady } from '@/views/tour/tour'
import { mapActions, mapGetters } from 'vuex'
import AddEdit from './AddEdit.vue'
import Tristate from './Components/Tristate.vue'

export default {
  components: {
    VueGoodTable,
    BFormGroup,
    BFormInput,
    Pagination,
    BButton,
    AddEdit,
    BOverlay,
    ManagePermissions,
    NoPermission,
    Tristate,
  },

  data() {
    return {
      pageLength: 3,
      dir: false,
      rows: [],
      productivity: [],
      productivityList: [
        { text: 'Productive', value: 'PRODUCTIVE', color: 'success' },
        { text: 'Neutral', value: 'NEUTRAL', color: 'primary' },
        { text: 'Unproductive', value: 'UNPRODUCTIVE', color: 'danger' },
      ],
      totalItems: 0,
      columns: [
        {
          label: this.$t('Activity'),
          field: 'activity',
        },
        {
          label: this.$t('Productivity'),
          field: 'productivity',
        },
        {
          label: this.$t('Scope'),
          field: 'scope',
        },
        {
          label: this.$t('Time Used'),
          field: 'time_used',
        },
      ],
      searchTerm: '',
      isProcessing: false,
      permissions: ['productivity.update'],
      canManagePermissions: this.$store.state.permissions.canManagePermissions.value,
      checkingPermission: false,
      needPermissionCheck: false,
    }
  },
  computed: {
    ...mapGetters('permissions', ['canUpdateProductivity']),
  },
  created() {
    this.getProjectProductivitySettings()

    EventBus.$on('permission-updated', permission => {
      if (this.permissions.indexOf(permission) !== -1) {
        this.needPermissionCheck = true
        setTimeout(() => this.checkUserPermission(), 2000)
      }
    })
    this.checkUserPermission()
  },
  methods: {
    ...mapActions('permissions', ['setProductivityEditPermission']),
    checkUserPermission() {
      if (!this.canUpdateProductivity || this.needPermissionCheck) {
        this.checkingPermission = true
        checkPermission(this.permissions, permissions => {
          const canUpdateProductivity = permissions['productivity.update'] === true
          this.setProductivityEditPermission(canUpdateProductivity)

          this.$nextTick(() => {
            this.checkingPermission = false
            this.needPermissionCheck = false
          })
        })
      }
    },
    getProjectProductivitySettings(page = 1) {
      this.isProcessing = true
      useApollo.project.getProjectProductivitySettings({
        projectId: this.$store.state.project.selectedProject,
        first: 20,
        between: {
          startTime: this.SUBTRACT_DAYS(this.TODAY(), 30).start,
          endTime: this.TODAY({ format: 'YYYY-MM-DD HH:mm:ss' }),
          timezone: this.$store.state.project.company.timezoneOffset?.filter,
        },
        page,
      }).then(response => {
        const productivitySettings = []
        const selectedSettings = []
        this.totalItems = response.data.project.productivitySettings.total
        response.data.project.productivitySettings.data.forEach(setting => {
          productivitySettings.push({
            activity: setting.title, productivity: setting.productivity, scope: 'Project', time_used: this.CONVERT_HM(setting.duration),
          })
          // selectedSettings.push(setting.productivity)

          // eslint-disable-next-line no-nested-ternary
          selectedSettings.push(setting.productivity === 'PRODUCTIVE' ? 'yes' : (setting.productivity === 'NEUTRAL' ? 'neutral' : 'no'))
        })
        this.rows = productivitySettings
        this.productivity = selectedSettings
        setComponentReady()
      }).catch(err => {
        console.error(err)
      }).finally(() => { this.isProcessing = false })
    },
    updateProductivitySetting(item, newValue) {
      this.processing = true
      // eslint-disable-next-line no-nested-ternary
      const productivity = newValue === 'yes' ? 'PRODUCTIVE' : (newValue === 'no' ? 'UNPRODUCTIVE' : 'NEUTRAL')
      useApollo.project.updateProductivityInfo({
        projectId: this.$store.state.project.selectedProject,
        title: item.row.activity,
        productivity,
      }).then(() => {
        this.showSuccessMessage({
          data: {
            message: `Productivity Setting for ${item.row.activity} updated successfully.`,
          },
        })
      }).catch(() => {
        this.showError(`Error updating productivity setting for ${item.row.activity} !`)
      }).finally(() => { this.processing = false })
    },

  },

}
</script>
