<template>

  <div class="wrapper">

    <label
      v-b-tooltip.hover="tooltipNo"
      class="btn_container"
    >
      <input
        type="radio"
        class="no"
        value="no"
        :checked="value === 'no'"
        @change="()=> {
          value = 'no';
          $emit('change', 'no');
        }"
      >
      <div class="checkmark no"><i class="fa fa-times" /></div>
    </label>
    <label
      v-b-tooltip.hover="tooltipNeutral"
      class="btn_container"
    >
      <input

        type="radio"
        class="neutral"
        value="neutral"
        :checked="value === 'neutral'"

        @change="()=> {
          value = 'neutral';
          $emit('change', 'neutral');
        }"
      >
      <div class="checkmark neutral"><i class="fa fa-circle-thin" /></div>
    </label>
    <label
      v-b-tooltip.hover="tooltipYes"
      class="btn_container"
    >

      <input
        type="radio"
        class="yes"
        value="yes"
        :checked="value === 'yes'"
        @change="()=> {
          value = 'yes';
          $emit('change', 'yes');
        }"
      >
      <div class="checkmark yes"><i class="fa fa-check" /></div>
    </label>
  </div>
</template>
<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: String,
      default: 'neutral',
    },
    tooltipYes: {
      type: String,
      default: '',
    },
    tooltipNo: {
      type: String,
      default: '',
    },
    tooltipNeutral: {
      type: String,
      default: '',
    },
  },

}

</script>
<style scoped>
.wrapper{
  width:98px;
  background: rgba(0, 0, 0, 0.4);
  margin:3px;
  border-radius: 30px;
  cursor: pointer;
}
.btn_container{
  display:inline-block;
  margin:0px;
  cursor: pointer;
}

input[type="radio"] {
    display: none;
}

.btn_container .checkmark{
  width:30px;
  height:30px;
}

.checkmark{
  padding:0px;
  margin:0px;
}

.no:checked + .checkmark {
  background-color: red;
  border-radius:30px;
  border:1px solid transparent;

    -moz-animation-duration: 0.4s;
    -moz-animation-name: slidein;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-name: slidein;
    animation-duration: 0.4s;
    animation-name: slidein;
    -webkit-transform: translateZ(0);
}

.neutral:checked + .checkmark {
  background-color:  #aaa;
  border:1px solid rgb(122, 119, 119);
  border-radius:30px;

  background: rgb(238, 238, 238);
    background: -moz-linear-gradient(top, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(238, 238, 238, 1)), color-stop(100%, rgba(204, 204, 204, 1)));
    background: -webkit-linear-gradient(top, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
    background: -o-linear-gradient(top, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
    background: -ms-linear-gradient(top, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
    background: linear-gradient(to bottom, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
}
.yes:checked + .checkmark {
  background-color: green;

  border-radius:30px;
  border:1px solid transparent;
  background: rgb(32, 213, 50);
    background: -moz-linear-gradient(top, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(32, 213, 50, 1)), color-stop(100%, rgba(28, 195, 1, 1)));
    background: -webkit-linear-gradient(top, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    background: -o-linear-gradient(top, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    background: -ms-linear-gradient(top, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    background: linear-gradient(to bottom, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);

   -moz-animation-duration: 0.4s;
    -moz-animation-name: slideno;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-name: slideno;
    animation-duration: 0.4s;
    animation-name: slideno;
}

.fa{
  margin-left:6px;
  margin-top:6px;
  font-size: 16px;
  color:#fff;
  /* line-height:30px; */
}

.checkmark.neutral{
  margin-left: 2px;
}
.checkmark.yes{
  margin-left: 6px;
}
.fa-times{
  margin-left:8px;
}

@keyframes slidein {
    from {
        transform: translate(50px, 0);
    }
    to {
        transform: translate(0px, 0px);
    }
}

@keyframes slideno {
    from {
      transform: translate(-50px,0);
    }
    to {
        transform: translate( 0px, 0px);
    }
}

@keyframes returnLeft {
    from {
        transform: translate(-50px,0);
    }
    to {
        transform: translate(0px,0);
    }
}

@keyframes returnRight {
    from {
        transform: translate(50px,0);
    }
    to {
        transform: translate(0px,0);
    }
}

</style>
