<template>
  <b-card
    class="text-center p-2"
  >
    <div class="d-flex justify-content-center">
      <feather-icon
        icon="AlertCircleIcon"
        color="#ea5455"
        size="24"
        class="mr-1"
      />
      <h4>You no longer have permission to this page</h4>
    </div>

    <b-button
      variant="primary"
      class="my-2 btn-sm-block"
      :to="{ name: 'home' }"
    >
      Go to home
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
  },
}
</script>
